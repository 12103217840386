<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="lg" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow" :modal-class="[id]"
      @ok="ok" @hidden="hidden">

      <AlertFeedback v-if="alertMsg != null" :msg="alertMsg" :details="alertMsgDetails.list" :detailTitle="alertMsgDetails.title" :alertState="alertState" @resetAlert="resetAlert"/>

      <ListFilter :termOnly="true" @applyFilter="applyFilter"/>
      
      <div class="grid-toolbar border" v-if="allowManage">
        <template v-if="canAdd()">
          <span :id="`BTN_ADD_${id}`">
            <b-btn @click="modalOpen(true)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
          </span>
          <b-popover :target="`BTN_ADD_${id}`" triggers="hover" placement="top">
            {{ $t('button.add') }}
          </b-popover>
        </template>
        <template v-if="canView()">
          <span :id="`BTN_EDIT_${id}`">
            <b-btn :disabled="disableEdit" @click="modalOpen(false)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
          </span>
          <b-popover :target="`BTN_EDIT_${id}`" triggers="hover" placement="top">
            {{ $t('button.edit') }}
          </b-popover>
        </template>
        <template v-if="canAdd()">
          <span :id="`BTN_DUPLICATE_${id}`">
            <b-btn :disabled="disableDuplicate" @click="showDuplicateDialog"><font-awesome-icon :icon="['far','clone']"/></b-btn>  
          </span>
          <b-popover :target="`BTN_DUPLICATE_${id}`" triggers="hover" placement="top">
            {{ $t('button.duplicate') }}
          </b-popover>
        </template>
        <template v-if="canDelete()">
          <span :id="`BTN_DELETE_${id}`">
            <b-btn :disabled="disableDelete" @click="rowDelete"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
          </span>
          <b-popover :target="`BTN_DELETE_${id}`" triggers="hover" placement="top">
            {{ $t('button.delete') }}
          </b-popover>
        </template>
      </div>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height" id="myGrid"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            :columnDefs="columnDefs"
            :context="context"
            :defaultColDef="defaultColDef"
            :getRowId="params => params.data.uuId"
            noRowsOverlayComponent="noRowsOverlay"
            :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            pagination
            :paginationPageSize="1000"
            :rowData="rowData"
            rowModelType="serverSide"
            :rowSelection="multiple? 'multiple':'single'"
            rowMultiSelectWithClick
            :serverSideInfiniteScroll="true"
            :sideBar="false"
            suppressDragLeaveHidesColumns
            suppressCellFocus
            suppressContextMenu
            suppressMultiSort
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ ok, cancel }">
        <template v-if="allowSelect">
          <b-button :disabled="disableOk" size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('MANAGE' === mode?'button.close':'button.cancel') }}</b-button>
      </template>
    </b-modal>
    
    <AccessPolicyModal v-if="accessPolicyShow" :id="accessPolicyId" :show.sync="accessPolicyShow" @success="accessPolicySuccess" :title="accessPolicyTitle"/>

    <b-modal :title="duplicateTitle"
        v-model="duplicateShow"
        @hidden="duplicateCancel"
        content-class="shadow"
        no-close-on-backdrop
        >
      <b-form-group :label="$t('access_policy.field.name')" label-for="name">
        <b-input-group>
          <b-form-input id="name" type="text"
            :data-vv-as="$t('access_policy.field.name')"
            data-vv-name="duplicate.name"
            data-vv-delay="500"
            trim
            v-model="duplicateName"/>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showDuplicateNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('duplicate.name') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{ cancel }">
          <b-button v-if="duplicateInProgress" disabled size="sm" variant="success"><b-spinner small type="grow" />{{ $t('button.processing') }}</b-button>
          <b-button v-else size="sm" variant="success" @click="duplicateOk">{{ $t('button.duplicate') }}</b-button>
          <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="$t('access_policy.confirmation.title_delete')"
        v-model="confirmDeleteShow"
        @ok="confirmDeleteOk"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ $t(selected.length > 1? 'access_policy.confirmation.delete_plural':'access_policy.confirmation.delete') }}
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <b-modal :title="ecTitle"
        v-model="ecShow"
        @ok="ecOk"
        @hidden="ecShow=false"
        content-class="shadow"
        no-close-on-backdrop
        >
      <div class="d-block">
        {{ ecContent }}
      </div>
      <template v-slot:modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.ok') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { cloneDeep, debounce } from 'lodash';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import alertStateEnum from '@/enums/alert-state';
import { strRandom } from '@/helpers';
import { filterOutViewDenyProperties, columnDefSortFunc } from '@/views/management/script/common'
import { accessPolicyService, viewProfileService } from '@/services';
import { fieldValidateUtil } from '@/script/helper-field-validate';
import ListFilter from '@/components/ListFilter/ListFilter';
import AccessPolicyModal from '@/components/modal/AccessPolicyModal';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      accessPolicyService.list(self.buildParams(params)).then((response) => {
        self.totalRecords = response.arg_total;
        params.successCallback(response.data, response.arg_total);
        if(self.gridApi && self.modalShow) {
          self.gridApi.deselectAll();
          if(self.selected && self.selected.length > 0) {
            const selected = cloneDeep(self.selected);
            self.gridApi.forEachNode(function(node) {
              if (selected.includes(node.data.uuId)) {
                node.setSelected(true);
                selected.splice(selected.indexOf(node.data.uuId), 1);
              }
            });
          }
        }
        self.gridApi.hideOverlay();
        if (response.arg_total === 0) {
          self.showNoRowLabel(null)
        }
      })
      .catch(e => {
        params.successCallback([], 0);
        if (e != null && e.response != null && e.response.status == 403) {
          self.showNoRowLabel(self.$t('entity_selector.error.insufficient_permission_to_show_data'))
        }
      });
    }
  }
}

export default {
  name: 'AccessPolicySelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    AccessPolicyModal,
    ListFilter,
    AlertFeedback: () => import('@/components/AlertFeedback'),
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    mode: {
      type: String,
      default: 'BOTH', // ['SELECT','MANAGE','BOTH']
    },
    title: {
      type: String,
      default: null
    }
  },
  data: function() {
    return {
      id: `ACCESS_POLICY_LIST_${strRandom(5)}`,
      permissionName: 'ACCESS_POLICY',
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      disableEdit: true,
      disableDelete: true,
      disableDuplicate: true,
      disableOk: true,
      selected: [],

      accessPolicyId: null,
      accessPolicyShow: false,
      alertMsg: null,
      alertMsgDetails: { title: null, list: [] },
      alertState: alertStateEnum.SUCCESS,

      confirmDeleteShow: false,
      totalRecords: 0,
      
      searchFilter: '',

      duplicateShow: false,
      duplicateName: null,
      duplicateInProgress: false,
      lastOpenColumnMenuParams: null,
      noRowsMessage: null,
      noRowsOverlayComponentParams: null,
      ecShow: false,
      entityCol: {
        entityName: null
        , entityId: null
        , cantDeleteDetails: null // special use case. It is used when entity cant be deleted due to specific reason. Expected format: { title, content }
      },
      cantDeleteEntities: []
    };
  },
  beforeMount() {
    this.userId = this.$store.state.authentication.user.uuId;
    const profileKey = 'access_policy_selector_list';
    const getColumnDefs = (c) => {
      return {
        colId: c.colId
        , width: c.actualWidth
        , sort: c.sort != null? c.sort : null
        , sortIndex: c.sortIndex != null? c.sortIndex : null
      }
    }

    const self = this;
    this.gridOptions = {
      onSelectionChanged: function(event) {
        self.selected.splice(0, self.selected.length, ...(event.api.getSelectedNodes().map(i => i.data.uuId)));
        self.disableEdit = self.disableDuplicate = self.selected.length != 1;
        self.disableDelete = self.selected.length < 1;
        self.disableOk = (self.multiple? (self.selected.length < 1): (self.selected.length != 1));
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.columnApi
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.columnApi.moveColumn(colKey, columnMenuColumnIndex + 1);
        }
        const cols = params.columnApi.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.columnApi.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.columnApi.moveColumn(c.colId, index);
        }

        const columns = params.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onSortChanged: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onDragStopped: function(event) {
        const columns = event.columnApi.getAllDisplayedColumns();
        self.settings[profileKey] = columns.map(c => getColumnDefs(c));
        self.updateViewProfile();
      },
      onFirstDataRendered: function(event) {
        if (self.newToProfile != null && self.newToProfile == true) {
          self.newToProfile = null;
          self.gridApi.sizeColumnsToFit();
          self.$nextTick(() => {
            const columns = event.columnApi.getAllDisplayedColumns();
            self.settings[profileKey] = columns.map(c => getColumnDefs(c));
            self.updateViewProfile();
          })
        }
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('access_policy.field.name'),
        field: 'uuId',
        cellRenderer: 'detailLinkCellRenderer',
        checkboxSelection: true,
        lockVisible: true,
        pinned: 'left',
        minWidth: 200,
        hide: false,
        sort: 'asc'
      },
      {
        headerName: this.$t('access_policy.field.description'),
        field: 'description',
        hide: false
      },
      {
        headerName: this.$t('field.identifier_full'),
        field: 'identifier'
      }
    ];

    filterOutViewDenyProperties(this.columnDefs, 'ACCESS_POLICY', []);
    this.columnDefs.sort(columnDefSortFunc);
    
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      lockPinned: true,
      hide: true,
      menuTabs: ['columnsMenuTab']
    };

    this.context = {
      componentParent: self
    };
  },
  mounted() {
    this.loadViewProfile();
  },
  created() {
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage
    }
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    this.userId = null;
    this.newToProfile = null;
  },
  watch: {
    async show(newValue) {
      if(newValue) {
        this.resetAlert();
        await this.loadViewProfile();
      } else {
        //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
        this.gridApi = null;
        this.gridColumnApi = null;
      }
      this.updateModalShow(newValue);
    }
  },
  computed: {
    allowSelect() {
      return !this.mode || (this.mode != 'MANAGE');
    },
    allowManage() {
      return this.mode === 'MANAGE' || this.mode === 'BOTH';
    },
    accessPolicyTitle() {
      return this.accessPolicyId && this.accessPolicyId.indexOf('ACCESS_POLICY_NEW') == -1? this.$t('access_policy.title_detail'): this.$t('access_policy.title_new');
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    labelTitle() {
      return this.title? this.title : this.$t('access_policy.title_selector');
    },
    showDuplicateNameError() {
      return fieldValidateUtil.hasError(this.errors, 'duplicate.name');
    },
    duplicateTitle() {
      return this.$t('access_policy.title_duplicate');
    },
    ecTitle() {
      if (this.entityCol != null && this.entityCol.cantDeleteDetails != null) {
        return this.entityCol.cantDeleteDetails.title != null? this.entityCol.cantDeleteDetails.title : ''
      } else {
        return ''
      }
    },
    ecContent() {
      if (this.entityCol != null && this.entityCol.cantDeleteDetails != null) {
        return this.entityCol.cantDeleteDetails.content != null? this.entityCol.cantDeleteDetails.content : ''
      } else {
        return ''
      }
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };

      updateData();
      
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
    
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.filter = this.searchFilter;
      return params;
    },
    modalOpen(isNew) {
      if(isNew) {
        this.accessPolicyId = `ACCESS_POLICY_NEW_${strRandom(5)}`;
      } else {
        this.accessPolicyId = this.selected[0];
      }
      this.accessPolicyShow = true;
      this.resetAlert();
    },
    accessPolicySuccess(payload) {
      this.gridApi.refreshServerSide({ purge: true });
      this.resetAlert({ msg: payload.msg });
      this.scrollToTop();
    },
    rowDelete() {
      this.confirmDeleteShow = true;
    },
    async confirmDeleteOk(){ 
      const selectedEntities
        = this.gridOptions.api.getSelectedNodes().map(i => {
          return {
            uuId: i.data.uuId,
            name: i.data.name != null? i.data.name : i.data.label
          }
        });

      if (selectedEntities.length < 1) {
        this.gridApi.refreshServerSide({ purge: true });
        return
      }
      const requests = []
      for (const entity of selectedEntities) {
        requests.push(accessPolicyService.getUserIds(entity.uuId))
      }

      if (requests.length > 0) {
        await Promise.allSettled(requests)
        .then(responses => {
          for (const [i, resp] of responses.entries()) {
            const entity = selectedEntities[i]
            if (resp.status == 'fulfilled') {
              const rValue = resp.value
              if (!Array.isArray(rValue) || rValue.length == 0 
                  || !Object.hasOwn(rValue[0], 'userIds')
                  || !Array.isArray(rValue[0].userIds)) {
                entity.cantDeleteDetails = { 
                  title: this.$t('access_policy.cant_delete.title')
                  , content: this.$t('access_policy.cant_delete.insufficient_permission', [entity.name])
                }
                continue
              }

              const count = rValue[0].userIds.length
              if (count > 0) {
                entity.cantDeleteDetails = { 
                  title: this.$t('access_policy.cant_delete.title')
                  , content: this.$t(`access_policy.cant_delete.already_in_use${count > 1? '_plural':''}`, [entity.name, count])
                }
                continue
              }
            } else {
              entity.cantDeleteDetails = { 
                title: this.$t('access_policy.cant_delete.title')
                , content: this.$t('access_policy.cant_delete.unexpected_error', [entity.name])
              }
              continue
            }
          }
        })
      }
      this.cantDeleteEntities.splice(0, this.cantDeleteEntities.length, ...selectedEntities.filter(i => i.cantDeleteDetails != null))
      const toDeleteIdNames = selectedEntities.filter(i => i.cantDeleteDetails == null)
      const toDeleteIds = toDeleteIdNames.map(i => { return { uuId: i.uuId } });

      let alertState = alertStateEnum.SUCCESS;
      let alertMsg = this.$t(`access_policy.delete${toDeleteIds.length > 1? '_plural':''}`);
      let alertMsgDetailTitle = null;
      let alertMsgDetailList = [];

      await accessPolicyService.remove(toDeleteIds)
      .then(response => {
        if (response.status == 207) {
          alertState = alertStateEnum.WARNING;
          alertMsg = this.$t('access_policy.delete_partial');
          alertMsgDetailTitle = this.$t(`access_policy.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
          const feedbackList = response.data[response.data.jobCase];
          for (let i = 0, len = feedbackList.length; i < len; i++) {
            const feedback = feedbackList[i];
            if (feedback.clue == 'OK') {
              continue;
            }
            const targetId = toDeleteIds[i].uuId;
            const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
            alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
          }
        }
      })
      .catch(e => {
        alertState = alertStateEnum.ERROR;
        alertMsg = this.$t(`access_policy.error.delete_failure${toDeleteIds.length > 1? '_plural' : ''}`);
        if (e.response) {
          const response = e.response;
          if (response.status == 422) {
            alertMsgDetailTitle = this.$t(`access_policy.error.delete_partial_detail_title${toDeleteIds.length > 1? '_plural' : ''}`);
            const feedbackList = response.data[response.data.jobCase];
            for (let i = 0, len = feedbackList.length; i < len; i++) {
              const feedback = feedbackList[i];
              if (feedback.clue == 'OK') {
                continue;
              }
              const targetId = toDeleteIds[i].uuId;
              const foundObj = toDeleteIdNames.find(item => targetId === item.uuId);
              alertMsgDetailList.push(foundObj != null && foundObj.name != null? foundObj.name : targetId);
            }
          } else if (403 === response.status) {
            alertMsg = this.$t('error.authorize_action');
          }
          this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
          this.scrollToTop();
        }
      });

      if (alertState !== alertStateEnum.ERROR) {
        this.gridApi.refreshServerSide({ purge: true });
      }

      const alertPayload = {
        msg: alertMsg,
        alertState: alertState
      }
      if (alertMsgDetailList.length > 0) {
        alertPayload.details = alertMsgDetailList;
        alertPayload.detailTitle = alertMsgDetailTitle;
      }
      this.resetAlert(alertPayload);

      if (this.cantDeleteEntities.length > 0) {
        this.prepareCantDeleteDialog();
      }
    },
    prepareCantDeleteDialog() {
      if (this.cantDeleteEntities.length == 0) {
        return;
      }
      const entity = this.cantDeleteEntities.shift()
      this.entityCol.entityName = entity.name;
      this.entityCol.entityId = entity.uuId;
      this.entityCol.cantDeleteDetails = entity.cantDeleteDetails;
      this.ecShow = true
    },
    ecOk() {
      setTimeout(() => {
        this.prepareCantDeleteDialog()
      }, 0)
    },
    httpAjaxError(e) {
      const response = e.response;
      let alertMsg = this.$t('error.internal_server');
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action');
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR });
      this.scrollToTop();
    },
    scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.id}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 300);
    },
    updateSelected(newValue) {
      this.selected.splice(0, this.selected.length, ...newValue);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    ok() {
      const details = this.gridApi.getSelectedNodes().map(i => { return {uuId: i.data.uuId, name: i.data.name} });
      this.$emit('ok', { ids: [...this.selected], details: details });
      this.$emit('input', [...this.selected]);
      
    },
    hidden() {
      this.selected.splice(0, this.selected.length);
      this.$emit('update:show', false);
    },
    openDetail(id){
      this.accessPolicyId = id;
      this.accessPolicyShow = true;
      this.resetAlert();
    },
    applyFilter(pattern) {
      this.searchFilter = pattern;
      this.gridApi.refreshServerSide({ purge: true });
    },
    showDuplicateDialog() {
      this.resetAlert();
      const origName = this.gridApi.getRowNode(this.selected[0]).data.name;
      this.duplicateName = `${origName} (Copy)`;
      this.duplicateShow = true;
    },
    duplicateOk() {
      this.duplicateEntity();
    },
    duplicateEntity: debounce(function() {
      this.duplicateInProgress = true;
      if(!this.duplicateName || this.duplicateName.trim().length < 1) {
        this.errors.add({
          field: `duplicate.name`,
          msg: this.$i18n.t('error.missing_argument', [this.$i18n.t('access_policy.field.name')])
        });
      }
      this.$validator.validate().then(valid => {
        if (valid && this.errors.items.length < 1) {
          accessPolicyService.clone(this.selected[0], { name: this.duplicateName })
          .then(() => {
            this.resetAlert({ msg: this.$t('access_policy.duplicate') });
            this.gridApi.refreshServerSide({ purge: true });
          })
          .catch(e => {
            let  alertMsg = this.$t('error.clone.failure', [this.$t('entityType.ACCESS_POLICY')]);
            if(e.response && e.response.status == 404 && e.response.data && e.response.data.jobClue != null) {
              const clue = e.response.data.jobClue.clue;
              if ('Unknown_holder' === clue) {
                alertMsg = this.$t('access_policy.error.duplicate_not_found');
              }
              this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
              this.scrollToTop();
            } else {
              this.httpAjaxError(e);
            }
          })
          .finally(() => {
            this.duplicateShow = false;
            this.errors.clear();
            //Make sure the dialog is closed before reenable duplicate button to avoid button spamming.
            this.$nextTick(() => {
              this.duplicateInProgress = false;
            });
          });
        } else {
          this.duplicateInProgress = false;
        }
      });
    }, 100),
    duplicateCancel() {
      this.duplicateShow = false;
      this.errors.clear();
    },
    updateViewProfile() {
      viewProfileService.update([this.settings], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      viewProfileService.create([this.settings],
                        this.userId).then((response) => {  
        const data = response.data[response.data.jobCase];
        this.settings.uuId = data[0].uuId;
        this.newToProfile = true;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    async loadViewProfile() {
      const self = this;
      await this.$store.dispatch('data/viewProfileList', self.userId).then((value) => {  
        const profileData = value;
        if (profileData.length === 0) {
          self.createViewProfile();
        }
        else {
          self.settings = profileData[0];
         
          if (typeof self.settings.access_policy_selector_list !== 'undefined') {
            self.loadColumnSettings(self, self.settings.access_policy_selector_list);
          } else {
            self.newToProfile = true;
          }
        }
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    loadColumnSettings(data, columns) {
      // order the columns based upon the order in 'columns'
      let idx = 0;
      columns.forEach(function(col) {
        const index = data.columnDefs.findIndex((c) => c.field === col.colId);
        if (index !== -1) {
          data.columnDefs.splice(idx++, 0, data.columnDefs.splice(index, 1)[0]);
        }
      });
      
      for (const column of data.columnDefs) {
        const setting = columns.filter(c => c.colId === column.field);
        if (setting.length === 0) {
          column.hide = true;
        }
        else {
          column.hide = false;
          column.width = setting[0].width;
          column.sort = setting[0].sort;
          column.sortIndex = setting[0].sortIndex;
        }
      }
      
      if (data != null && data.gridOptions != null && data.gridOptions.api != null) {
        data.gridOptions.api.setColumnDefs([]);
        data.gridOptions.api.setColumnDefs(data.columnDefs);
      }
      return false;
    },
    resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertMsg = msg;
      this.alertState = alertState;
      this.alertMsgDetails.title = detailTitle;
      const list = this.alertMsgDetails.list;
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details);
      } else {
        list.splice(0, list.length);
      }
    },
    prepareNoRowsMessage() {
      if (this.noRowsMessage != null) {
        return this.noRowsMessage
      }
      return this.$t(`entity_selector.access_policy_grid_no_data`)
    },
    showNoRowLabel(msg=null) {
      this.noRowsMessage = msg
      let api = this.gridOptions != null? this.gridOptions.api : null
      if (api != null) {
        this.gridOptions.api.hideOverlay()
        setTimeout(() => {
          api.showNoRowsOverlay()
        })
      }
    }
  }
}


</script>